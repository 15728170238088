import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Gizlilik Politikası</h1>
      <p>
        Buglog olarak gizliliğinizin önemli olduğunu biliyoruz. Bu gizlilik
        politikası, uzantımızın kullanıcı verilerini nasıl topladığı, işlediği,
        sakladığı ve paylaştığı hakkında bilgi sağlar. Lütfen uzantımızı
        kullanmadan önce bu politikayı dikkatlice okuyun.
      </p>

      <h2>1. Toplanan Veriler</h2>
      <h3>1.1. Hangi Veriler Toplanır?</h3>
      <ul>
        <li>Kullanıcı kimlik doğrulama bilgileri (token).</li>
        <li>Proje ve hata detayları (hata başlığı, açıklama, proje bilgisi).</li>
        <li>Son kullanılan proje bilgileri.</li>
      </ul>

      <h3>1.2. Hangi Veriler Toplanmaz?</h3>
      <ul>
        <li>Tarama geçmişi veya cihaz bilgileri.</li>
        <li>Kişisel veriler (örneğin, ad, adres, kredi kartı bilgileri).</li>
      </ul>

      <h2>2. Verilerin Kullanımı</h2>
      <h3>2.1. Veriler Neden Toplanır?</h3>
      <ul>
        <li>Projelerin seçilmesi ve hata bildirimlerinin yapılması.</li>
        <li>Kimlik doğrulama işlemlerini yönetmek.</li>
        <li>Kullanıcı deneyimini geliştirmek.</li>
      </ul>

      <h3>2.2. Veriler Nasıl Kullanılır?</h3>
      <p>
        Toplanan veriler yalnızca uzantının temel işlevlerini gerçekleştirmek
        için kullanılır. Kullanıcıların açık izni olmadan başka amaçlar için
        kullanılmaz.
      </p>

      <h2>3. Verilerin Saklanması</h2>
      <p>
        Kullanıcı verileri, cihazınızın tarayıcı depolama alanında (local
        storage) saklanır. Bu veriler yalnızca uzantının işlevselliği için
        gereklidir ve hiçbir sunucuya gönderilmez.
      </p>

      <h2>4. Verilerin Paylaşımı</h2>
      <p>
        Buglog, kullanıcı verilerini hiçbir şekilde üçüncü taraflarla
        paylaşmaz. Kanuni bir gereklilik olmadıkça veriler yalnızca kullanıcı
        tarafından erişilebilir durumda kalır.
      </p>

      <h2>5. Kullanıcı Hakları</h2>
      <ul>
        <li>
          <strong>Verilere Erişim:</strong> Kullanıcılar, hangi verilerin
          toplandığını ve nasıl kullanıldığını görebilir.
        </li>
        <li>
          <strong>Verilerin Silinmesi:</strong> Kullanıcılar, tarayıcı ayarları
          üzerinden tüm verileri silebilir.
        </li>
        <li>
          <strong>İzin Devre Dışı Bırakma:</strong> Uzantının ayarlar bölümünden
          veri saklama işlemi devre dışı bırakılabilir.
        </li>
      </ul>

      <h2>6. Güvenlik</h2>
      <p>
        Buglog, kullanıcı verilerini korumak için gerekli tüm teknik önlemleri
        alır. Tüm veri işlemleri HTTPS protokolü ile yapılır ve veriler güvenli
        bir şekilde şifrelenir.
      </p>

      <h2>7. Çerezler ve İzleme</h2>
      <p>
        Buglog uzantısı çerez (cookie) veya izleme mekanizmaları kullanmaz. Tüm
        işlemler kullanıcı kontrolü altındadır.
      </p>

      <h2>8. Politika Güncellemeleri</h2>
      <p>
        Buglog gizlilik politikası zaman zaman güncellenebilir. Güncellemeler
        hakkında kullanıcılar bilgilendirilecek ve yeni politika bu sayfada
        yayınlanacaktır.
      </p>

      <h2>9. İletişim Bilgileri</h2>
      <p>
        Herhangi bir sorunuz veya endişeniz varsa bizimle iletişime geçebilirsiniz:
      </p>
      <ul>
        <li><strong>E-posta:</strong> destek@buglog.app</li>
        <li>
          <strong>Web Sitesi:</strong>{" "}
          <a href="https://www.buglog.app" target="_blank" rel="noreferrer">
            www.buglog.app
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
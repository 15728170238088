import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Bugs from './pages/Bugs/Bugs';
import Profile from './pages/Profile/Profile';
import AddProject from './pages/AddProject/AddProject';
import Register from './pages/Register/Register';
import BugDetail from './pages/BugDetail/BugDetail';
import './App.css';
import InvitePage from './pages/Invite/Invite';
import PrivacyPolicy from './pages/Privacy/Privacy';

function App() {
  const token = Cookies.get("token");

  return (
    <Router>
      <Routes>
        <Route path="/" element={token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
        <Route
          path="/dashboard"
          element={token === "11771177" ? <Navigate to="/login" /> : token ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route
          path="/login"
          element={token === "11771177" ? <Login /> : token ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route
          path="/project"
          element={token === "11771177" ? <Navigate to="/login" /> : token ? <Navigate to={`/project?token=${token}`} /> : <Navigate to="/" />}
        />
        <Route
          path="/bugs/:projectId/:isOwner/:projectName"
          element={token ? <Bugs /> : <Navigate to="/" />}
        />
        <Route
          path="/invite/:uniqid"
          element={<InvitePage />}
        />
        <Route
          path="/invite/:uniqid/:title"
          element={<InvitePage />}
        />
        <Route
          path="/profile"
          element={token === "11771177" ? <Navigate to="/login" /> : token ? <Profile /> : <Navigate to="/" />}
        />
        <Route
          path="/add-project"
          element={token === "11771177" ? <Navigate to="/login" /> : token ? <AddProject /> : <Navigate to="/" />}
        />
        <Route
          path="/register"
          element={token === "11771177" ? <Register /> : token ? <Navigate to="/dashboard" /> : <Register />}
        />
        <Route
          path="/bug_detail/:bugId"
          element={token ? <BugDetail /> : <Navigate to="/" />}
        />
        <Route
          path="/privacy"
          element={<PrivacyPolicy />}
        />
      </Routes>
    </Router>
  );
}

export default App;